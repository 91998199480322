import s from "./header.module.css";
import {
  faRotateLeft,
  faLightbulb,
  faPlus,
  faArrowLeft,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Timer from "../footer/Timer";
import Button from "../Button/Button";
import {
  setColor,
  stepBack,
  setCardBackIndex,
  setHint,
  restart,
} from "../../store/cardSlice";

// import { Link } from "react-router-dom";

export default function Header() {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(false);
  const [rools, setRools] = useState(false);

  // const [reload, setReload] = useState(false);
  const back = useSelector((state) => state.pyramid.backFont);
  const backCard = useSelector((state) => state.pyramid.backs);
  // const cards = useSelector((state) => state.pyramid);

  const hint = () => {
    dispatch(setHint(true));
    setTimeout(() => {
      dispatch(setHint(false));
    }, 1000);
  };

  const openOptions = () => {
    if (!options) {
      setOptions(true);
    } else {
      setOptions(false);
      setRools(false);
    }
  };

  const restartButton = () => {
    dispatch(restart());
  };

  const changeBackground = (index) => {
    dispatch(setColor(index));
  };

  const changeCardBack = (index) => {
    dispatch(setCardBackIndex(index));
  };

  const backMove = () => {
    // if (cards.steps <= 0) {
    //   return restartButton();
    // }
    dispatch(stepBack());
  };

  useEffect(() => {
    localStorage.setItem("restartPyramidTrue", false);
    localStorage.setItem("stepTrue", false);
  }, []);
  return (
    <div className={s.header}>
      <div className={s.buttons}>
        <a href="/">
          <Button
            icon={faArrowLeft}
            onClick={backMove}
          />
        </a>
        <Button
          icon={faLightbulb}
          onClick={hint}
        />
        <a href="/">
          <Button icon={faPlus} />
        </a>

        <a href="/">
          <Button
            icon={faRotateLeft}
            onClick={restartButton}
          />
        </a>
      </div>
    </div>
  );
}
