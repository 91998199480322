import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import s from "../header/header.module.css";

const Button = ({ icon, onClick }) => {
  return (
    <button
      className={`${s.btn_hover} ${s.color_5}`}
      onClick={onClick}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{ color: "#ffffff" }}
      />
    </button>
  );
};

export default Button;
